import styled from 'styled-components';

const MailContainer = styled.div`
    position: relative;
    display: flex;
    @media screen and (max-width: 667px){
        flex-direction: column;
    }
`;

export default MailContainer;
