import React from 'react';
import Map from '../Map';
import MailForm from '../MailForm';
import config from '../../config';
import MailContainer from './styledComponent';

function MailSection() {
  return (
    <MailContainer>
      <MailForm />
      <Map marker={[config.hqCoords.lon, config.hqCoords.lat]} />
    </MailContainer>
  );
}

export default MailSection;
