import React from 'react';
import { isMobile } from 'react-device-detect';
// import mapboxgl from 'mapbox-gl';
// import 'mapbox-gl/dist/mapbox-gl.css';
import { BrowserRouter } from 'react-router-dom';
import SuiMap from 'stoomlink-commons-ui/Map';
import styled from 'styled-components';
import { hasWindow } from '../../utils/dom';
import { Marker, MarkerBorder } from './markerStyles';

// =============================================================================
// Mapbox-GL (non-react) + Gatbsy Integration
//
// Heavily inspired by https://github.com/brendan-ward/gatsby-starter-mapbox
// =============================================================================

// =============================================================================
// Config
// =============================================================================

const boundsDesktop = [
  [4.371001393623203, 50.849106738058055],
  [4.358817130754318, 50.845260221613216],
];

const boundsMobile = [
  [4.364417961183989, 50.84903810886081],
  [4.360959202691873, 50.84519158675704],
];
const bounds = (isMobile) ? boundsMobile : boundsDesktop;

// Styles
// =============================================================================

const Wrapper = styled.div`
  width: 100%;
  height: ${(isMobile) ? '30vh' : '620px'};
`;

// =============================================================================
// Map
// =============================================================================

const Map = (props) => {
  if (!hasWindow) {
    return null; // if there is no window, we cannot render this component
  }
  const { marker } = props;

  return (
    <Wrapper>
      <BrowserRouter>
        <SuiMap
          fitBounds={bounds}
          interactive={false}
          themeAutoSwitch
          showLoadingIndicator={false}
        >
          <Marker
            coordinates={marker}
            anchor="center"
          >
            <MarkerBorder />
          </Marker>
        </SuiMap>
      </BrowserRouter>
    </Wrapper>
  );
};

export default Map;
