function mailLink(email, name, object) {
  return `mailto:${email}?subject=Belgian Mobility Company%2F${name}%20%E2%80%93%20${encodeURIComponent(object)}&bcc=z4k7v1k1e3w0f5b7@nextmoov.slack.com`;
}

export default {
  mapboxTheme: 'mapbox://styles/nextride/ckaffkwc80fb31jpkza4xasj9',
  mapboxToken: 'pk.eyJ1IjoibmV4dHJpZGUiLCJhIjoiY2thZ29jYXVjMDhqYTJ5cWJkZDducGtvZSJ9.AIopzyAm7aka6dEa7-bPOA',
  hqCoords: {
    lon: 4.3626013,
    lat: 50.8470948,
  },
  mailLink,
};
