import React, { useState, useEffect, useCallback } from 'react';
import Title from 'stoomlink-commons-ui/Title';
import BodyText from 'stoomlink-commons-ui/BodyText';
import regTest from '../../utils/mail';
import {
  Container,
  StyledButton, Input, TextArea,
  ValidationBanner,
} from './styledComponent';
import config from '../../config';

const { mailLink } = config;


function Mailform({
  title, smallTitle, buttonTitle, mailTitle, mailObject,
}) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [isValidEmail, setIsValidEmail] = useState(
    false
  );

  const [emptyFields, setEmptyFields] = useState(['name', 'email', 'message']);
  const [status, setStatus] = useState({
    attempts: 0,
    message: '',
    type: null,
  });


  function sendMessage() {
    fetch('https://hooks.slack.com/services/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',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(
          {
            text: '<!channel>, you got mail! 📩',
            attachments: [
              {
                fallback: `New contact request from ${formData.name}, ${formData.email}`,
                title: `${formData.name} ${mailTitle}`,
                title_link: mailLink(formData.email, formData.name, mailObject),
                text: `${formData.message}`,
              },
            ],
          }
        ),
      })
      .then(() => setStatus({ ...status, type: 'success', message: 'Your message has been sent' }))
      .catch((err) => setStatus({ ...status, type: 'error', message: err.message }));
  }

  const changeEmptyFields = useCallback(() => {
    const keys = Object.keys(formData);
    keys.map((key) => {
      if (formData[key].length >= 3 && emptyFields.includes(key)) {
        setEmptyFields(emptyFields.filter((field) => field !== key));
      } else if (formData[key].length < 3 && !emptyFields.includes(key)) {
        setEmptyFields([...emptyFields, key]);
      }
      return null;
    });
  }, [formData, emptyFields]);

  useEffect(() => {
    changeEmptyFields();
  }, [changeEmptyFields]);

  useEffect(() => {
    if (emptyFields.length === 0) {
      setStatus((s) => ({
        ...s,
        message: '',
      }));
    } else {
      setStatus((s) => ({
        ...s,
        message: emptyFields.length > 1
          ? `Please fill in these fields: ${emptyFields.join(', ')}`
          : `Please fill in this field: ${emptyFields}`,
        type: 'error',
      }));
    }
  }, [emptyFields]);

  const checkEmail = useCallback(() => {
    if (regTest(formData.email)) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
    }
  }, [formData]);

  useEffect(() => {
    checkEmail();
  }, [checkEmail]);


  function handleSubmit(e) {
    e.preventDefault();
    setStatus({
      ...status,
      attempts: status.attempts += 1,
    });
    if (emptyFields.length === 0 && isValidEmail) {
      sendMessage();
    }
  }

  return (
    <Container onSubmit={handleSubmit}>
      <>
        {(status.attempts > 0 && status.message.length > 0) && (
          <ValidationBanner type={status.type}>{status.message}</ValidationBanner>
        )}
        <Title level={2}>{title}</Title>
        {smallTitle && (<BodyText style={{ color: '#000000', margin: '6px 0', width: '100%' }}>{smallTitle}</BodyText>)}
        <Input
          type="text"
          placeholder="Full name*"
          required
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          style={{ width: '100%', marginTop: '24px' }}
          disabled={status.type === 'success'}
        />
        <div>
          <Input
            type="email"
            placeholder="Email*"
            required
            value={formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            style={{ width: '100%' }}
            disabled={status.type === 'success'}
          />
          {(!isValidEmail && status.attempts > 0) && (<span>This email is invalid</span>)}
        </div>
        <TextArea
          as="textarea"
          placeholder="Message*"
          value={formData.message}
          disabled={status.type === 'success'}
          onChange={(e) => setFormData({ ...formData, message: e.target.value })}
        />
      </>
      <StyledButton
        onClick={handleSubmit}
        disabled={status.type === 'success'}
      >
        {buttonTitle}
      </StyledButton>
    </Container>
  );
}

Mailform.defaultProps = {
  title: 'Say Hello!',
  buttonTitle: 'Send your message',
  mailTitle: 'just contacted you 🚀',
  mailObject: 'Let\'s get in touch',
};

export default Mailform;
