import styled from 'styled-components';
import { stoomlinkColors as colors } from 'stoomlink-commons-ui/colors';
import Button from '../Button';

export const Container = styled.form`
    padding: 32px;
    background: #FFFFFF;
    display: block;
    width: 100%;
    box-sizing: border-box;
    box-shadow: 0 16px 32px 0 rgba(0,0,0,0.10), 0 6px 13px 0 rgba(0,0,0,0.07), 0 3px 7px 0 rgba(0,0,0,0.06), 0 2px 4px 0 rgba(0,0,0,0.05), 0 1px 2px 0 rgba(0,0,0,0.04), 0 0 0 0 rgba(0,0,0,0.03);
    z-index: 1;
    width: 35%;
    position: absolute;
    right: 6%;
    align-self: center;
    @media screen and (max-width: 980px){
        width: 45%;
    }
    @media screen and (max-width: 667px){
        width: 100%;
        bottom: 10px;
        right: 0;
        position: relative;
        order: 2;
    }
    
    input {
        margin-top: 18px;
    }
    span {
        display: block;
        padding: 12px;
        background: ${colors.Pink200.hex}; 
        color: #FFFFFF;
        margin-top: 12px;
    }
    textarea {
        margin-top: 18px;
        height: 130px;
        width: 100%;
        resize: none;
    }
`;

export const StyledButton = styled(Button)`
    margin-top: 24px;
`;

export const ValidationBanner = styled.div`
    padding: 12px;
    background: ${(props) => (props.type === 'error' ? colors.Pink200.hex : colors.Green200.hex)}; 
    color: ${(props) => (props.type === 'error' ? colors.white.hex : colors.black.hex)};
    margin-bottom: 12px;
`;

export const Input = styled.input`
  position: relative;
  display: inline-flex;
  place-items: center;
  margin: 0;
  padding: 0 16px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.primaryShade1};
  border: 0;
  border-radius: 0;
  font-family: 'HN Text';
  font-size: 14px;
  letter-spacing: 0.08px;
  line-height: 22px;
  outline: transparent solid 2px;
  outline-offset: -2px;
  transition: background-color 0.25s cubic-bezier(.2, 0, .38, .9), border 0.25s cubic-bezier(.2, 0, .38, .9), color 0.25s cubic-bezier(.2, 0, .38, .9), outline 0.12s cubic-bezier(.2, 0, .38, .9);
  appearance: none;

  &::after {
    content: '';
    position: absolute;
    top: 2px;
    bottom: 2px;
    left: 2px;
    right: 2px;
    border: 1px solid transparent;
    pointer-events: none;
    transition: border 0.12s cubic-bezier(.2, 0, .38, .9);
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryShade2};
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.primaryShade3};
    outline: ${({ theme }) => `${theme.colors.secondary} solid 2px`}
  }
  &:focus {
    background-color: ${({ theme, disabled }) => !disabled && theme.colors.primaryShade2};
    outline: ${({ disabled, theme }) => !disabled && `${theme.colors.secondary} solid 2px`};
    &::after {
      border: ${({ disabled, theme }) => !disabled && (`1px solid ${theme.colors.primary}`)};
    }
  }
  &[disabled] {
    background-color: ${({ theme }) => theme.colors.primaryShade2};
    color: ${() => colors.Gray300.hex};
    cursor: not-allowed;
  }
`;

export const TextArea = styled(Input)`
  place-items: flex-start;
  padding: 12px 16px;
  height: 128px;
  width: 100%;
`;
