import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Marker as MarkerGL } from 'react-mapbox-gl';
import { colors } from '../../theme';

export const pulse = keyframes`
  O% {
    transform: scale(0);
    opacity: 0;
  }
  15% {
    opacity: 0.24;
  }
  100% {
    transform: scale(6);
    opacity: 0;
  }
`;

const borderPulse = keyframes`
  0% {
    box-shadow: inset 0px 0px 0px 3px #FFFFFF;
  }
  50% {
    box-shadow: inset 0px 0px 0px 5px #FFFFFF;
  }
  100% {
    box-shadow: inset 0px 0px 0px 3px #FFFFFF;
  }
`;

// See https://stackoverflow.com/questions/57149932/styled-components-breaks-in-production-only
export const Marker = styled((props) => <MarkerGL {...props} />)`
  width: 24px;
  height: 24px;
  background: ${colors.accent};
  border-radius: 50px;
  display: flex;
  justify-content: center;

  &::after, &::before{
    content: "";
    align-self: center;
    width: 100%;
    height: 100%;
    background: ${colors.accentLight};
    z-index: -1;
    border-radius: 50px;
    display: block;
    position: absolute;
    opacity: 0;
  }

  &::after {
    animation: 3s 0s ${pulse} cubic-bezier(.2, .8, .4, 1) infinite;
  }

  &::before {
    animation: 3s .64s ${pulse} cubic-bezier(.2, .8, .4, 1) infinite;
  }
`;

export const MarkerBorder = styled.div`
  width: 24px;
  height: 24px;
  box-shadow: inset 0px 0px 0px 3px #FFFFFF;
  animation: 4.5s 0s ${borderPulse} cubic-bezier(.2, 0, .38, .9) infinite;
  border-radius: 50px;
`;
